<!-- Telrik grid start  -->
<div class="main-body-con">
    <div class="page-container pt-4">
        <div class="page-hdr-outer ps-4 pe-4">
            <div class="page-hdr row flex-div">
                <div class=" col-6 col-sm-6 col-md-6 col-lg-6">
                    <div class="flex-div filter-list-comp">
                        <!-- <label style="margin-top: 20px;">Filter by</label> -->
                        <div style="margin-top: 6px;
                        margin-right: 10px;"><a [ngClass]="{'green' : !pinUnpinList, 'red': pinUnpinList}"
                                (click)="updateUserForProspect()">
                                <span><img src="assets/images/pin.png" alt="" width="16px" class="pin" /></span>
                            </a></div>
                        <select class="select-cs form-select" [(ngModel)]="selectedDefaultList"
                            (change)="onCreateListChange($event,'onchange')">
                            <option value="0">My Appointments </option>
                            <option value="1">My Team Appointments </option>
                            <option value="2">All Appointments</option>
                        </select>
                    </div>
                </div>
                <div class="col-6 col-sm-6 col-md-6 col-lg-6 flex-div justify-content-end rht-hdr-action">
                    <div class="dropdown reset me-3">

                        <a href="javascript:void(0)" class="dropdown-toggle" id="dropdownMenuButton3"
                            data-bs-toggle="dropdown" data-bs-auto-close="outside"
                            style="color: #122B74 !important;cursor:pointer  !important;"><label class="ps-2"><img
                                    src="assets/images/reset.png" class="me-2 import-img" width="13px"
                                    alt="" />Reset</label></a>

                        <div class="dropdown-menu reset-menu-div" aria-labelledby="dropdownMenuButton3">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="resetAll"
                                    (change)="onChangeResetAll($event)">
                                <label class="form-check-label" for="resetAll">
                                    Reset All
                                </label>
                            </div>
                            <!-- <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="filter"
                                    [(ngModel)]="selectResetArr['filter']">
                                <label class="form-check-label" for="filter">
                                    Filter
                                </label>
                            </div> -->

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="column"
                                    [(ngModel)]="selectResetArr['column']">
                                <label class="form-check-label" for="column">
                                    Column
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="sorting"
                                    [(ngModel)]="selectResetArr['sorting']">
                                <label class="form-check-label" for="sorting">
                                    Sorting
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="columnFilter"
                                    [(ngModel)]="selectResetArr['columnFilter']">
                                <label class="form-check-label" for="columnFilter">
                                    Column Filter
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="width"
                                    [(ngModel)]="selectResetArr['width']">
                                <label class="form-check-label" for="width">
                                    Column Width
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="columnSequence"
                                    [(ngModel)]="selectResetArr['columnSequence']">
                                <label class="form-check-label" for="columnSequence">
                                    Column Sequence
                                </label>
                            </div>

                            <div class="flex-div"><button class="btn btn-outline-primary me-3"
                                    (click)="selectResetArr={};">Clear</button> <button class="btn btn-primary"
                                    (click)="resetAllfunctionality()">Apply</button></div>
                        </div>
                    </div>
                    <!-- <a href="javascript:void(0)" *ngIf="isValidUser" (click)="exportGlobal()"> <label class="ps-2"><img
                                src="assets/images/file-export.svg" class="me-2 import-img" width="13px"
                                alt=" " />Export</label></a> -->

                    <div class="dropdown export-dropdown" *ngIf="isValidUser">
                        <a href="javascript:void(0)" class="dropdown-toggle" id="dropdownMenuButton1"
                            style="color: #122B74 !important;cursor:pointer  !important;" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <label class="ps-2"><img src="assets/images/import.svg" class="me-2 import-img" width="13px"
                                    alt="" />Export</label>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <!-- <li (click)="DownloadExportFile('Selected')"><a href="javascript:void(0)"
                                                 [style.pointer-events]="mySelection.length==0 ? 'none' : 'auto'"
                                                                        [style.color]="mySelection.length==0 ? 'grey' : '#122B74 !important'"
                                                style="color: #122B74 !important;cursor:pointer  !important;"
                                                class="dropdown-item">Selected Contact</a></li> -->
                            <li (click)="DownloadExportFile('All')"><a href="javascript:void(0)"
                                    style="color: #122B74 !important;cursor:pointer  !important;" title=""
                                    class="dropdown-item">All
                                    Appointment</a></li>
                        </ul>
                    </div>

                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="javascript:void(0)" (click)="openAddappointmentModal()" title="" class="active-view ">
                        <img src="assets/images/plus.svg" alt="" /><span class="ps-2">Add Appointment</span>
                    </a>

                </div>
                <div class=" col-6 col-sm-6 col-md-6 col-lg-6" style="display: none;">
                    <div class="flex-div filter-list-comp">
                        <!-- <label style="margin-top: 20px;">Filter by</label> -->
                        <div style="margin-top: 25px ; margin-left:15px;"><a
                                [ngClass]="{'green' : !pinUnpinList, 'red': pinUnpinList}"
                                (click)="updateUserForProspect()">
                                <span><img src="assets/images/pin.png" alt="" width="16px" class="pin" /></span>
                            </a></div>
                        <select class="select-cs form-select" style="margin-top: 20px;margin-left: 20px;"
                            [(ngModel)]="selectedDefaultList" (change)="onCreateListChange($event,'onchange')">
                            <option value="0">My Appointments </option>
                            <option value="1">My Team Appointments </option>
                            <option value="2">All Appointments</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-con mx-4 ">
            <div class="grid__wrapper wrp-pros custom-height1">
                <kendo-grid [reorderable]="true" [resizable]="true" filterable="menu"
                    (filterChange)="gridFilterChange($event)" [data]="finalAppointmentList" [sortable]="true"
                    [sort]="sort" (sortChange)="sortChange($event)" [skip]="skip" [filter]="gridFilters"
                    [pageable]="{ pageSizes: true, position: 'top' }" [pageSize]="pageSize"
                    (pageChange)="paginationChange($event)" (columnVisibilityChange)="onColumnMenuInit($event)" #grid
                    (columnReorder)="onColumnReorder($event)" (columnResize)="columnResize($event)"
                    [sortable]="{allowUnsort: false,mode: false ? 'multiple' : 'single'}">
                    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                        <div class="flex-div justify-content-between grid-hd">
                            <div></div>
                            <!-- <div class="flex-div">
                                <kendo-textbox placeholder="Search in all columns..." #searchText
                                    [value]="filterData?.searchText" (change)="searchValue(searchText.value)"
                                    [style.width.px]="205"></kendo-textbox><img src="assets/images/search.svg"
                                    (click)="onFilter()" alt="" class="ms-2" />
                            </div> -->
                            <div class="flex-div">
                                <div class="flex-div">
                                    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                                    <kendo-pager-numeric-buttons
                                        [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                                    <kendo-pager-next-buttons></kendo-pager-next-buttons>
                                    <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
                                    <kendo-grid-messages pagerItems="Items" [pagerItemsPerPage]="'Items per page'">
                                    </kendo-grid-messages>
                                </div>
                                <kendo-pager-info class="bd-l"></kendo-pager-info>
                            </div>
                          <button [ngClass]="{ actbtn: isFilter }" class="filter-button-choose"
                                  (click)="setStyleOfPopup()">
                            <kendo-grid-column-chooser [allowHideAll]="false"></kendo-grid-column-chooser>
                          </button>
                        </div>

                    </ng-template>
                    <kendo-grid-column title="Actions" [reorderable]="false" [resizable]="false" [width]="70" class="overflow-visible"
                        field="Actions" [filterable]="false" [sortable]="false" [includeInChooser]="false">
                        <ng-template kendoGridCellTemplate let-rowData class="overflow-visible">
                            <a href="javascript:void(0)" title="" class="tbl-act pointer list-action"
                                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img
                                    src="assets/images/action.svg" alt="" /></a>
                            <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a (click)="openEditAppointmentModel(rowData)" class="dropdown-item"
                                        href="javascript:void(0)"><img src="../../assets/images/edit2.svg"
                                            alt="Edit Appointment" />
                                        <span>Edit Appointment</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="javascript:void(0)"
                                        (click)="openDeleteModel(rowData.id)"><img src="../../assets/images/trash.svg"
                                            alt="Delete" />
                                        <span>Delete</span></a>
                                </li>
                            </ul>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [hidden]="isGridColumnVisible('Appt Date')" field="dateofAppointment"
                        title="Appt Date" filter="date" format="{0:d}" [width]="GridColumnWidth('Appt Date')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span>{{dataItem.dateofAppointment | date: "MM/dd/yyyy" }}</span>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <date-range-filter [field]="column.field" [filter]="filter" [filterService]="filterService">
                            </date-range-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [hidden]="isGridColumnVisible('Subject')" field="subject" title="Subject"
                        [filterable]="true" [width]="GridColumnWidth('Subject')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="cr-wd">
                                <a (click)="openEditAppointmentModel(dataItem)" href="javascript:void(0)"
                                    pTooltip="{{ dataItem.subject }}" tooltipPosition="bottom"
                                    tooltipStyleClass="tooltipstyle">{{dataItem.subject }}</a>
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                            </kendo-grid-string-filter-menu>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [hidden]="isGridColumnVisible('Event Type')" field="typeofEventName"
                        title="Event Type" [filterable]="true" [width]="GridColumnWidth('Event Type')">
                        <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                            <kendo-multiselect [checkboxes]="true" [(ngModel)]="gridFilterArrayForEventType"
                                [autoClose]="false" [data]="eventTypeList" [valuePrimitive]="true"
                                (valueChange)="onEventTypeChange($event)" textField="value" valueField="value">
                            </kendo-multiselect>
                        </ng-template> -->
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex-div ph-no">
                                {{dataItem.typeofEventName}}
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="eventTypeList" [currentFilter]="filter"
                                [filterService]="filterService" [isPrimitive]="true"
                                [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [hidden]="isGridColumnVisible('Appointment Type')" field="typeofVisit"
                        title="Appointment Type" [filterable]="true" [width]="GridColumnWidth('Appointment Type')">
                        <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                            <kendo-multiselect [checkboxes]="true" [(ngModel)]="gridFilterForArrayAppointmentType"
                                [autoClose]="false" [data]="appointmentTypeList" [valuePrimitive]="true"
                                (valueChange)="onappointmentTypeChange($event)">
                            </kendo-multiselect>
                        </ng-template> -->
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex-div ph-no">
                                {{dataItem.typeofVisit}}
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="appointmentTypeList" [currentFilter]="filter"
                                [filterService]="filterService" [isPrimitive]="true"
                                [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [hidden]="isGridColumnVisible('Start Time')" title="Start Time" field="startTime"
                        title="Start Time" [filterable]="true" [width]="GridColumnWidth('Start Time')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex-div ph-no">
                                {{dataItem.startTime}}
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [hidden]="isGridColumnVisible('End Time')" [width]="GridColumnWidth('End Time')"
                        field="endTime" title="End Time" [filterable]="true">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex-div ph-no">
                                {{dataItem.endTime}}
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [hidden]="isGridColumnVisible('Requestor')" field="userName" title="Requestor"
                        [filterable]="true" [width]="GridColumnWidth('Requestor')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex-div ph-no">
                                {{dataItem.userName}}
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                            </kendo-grid-string-filter-menu>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [hidden]="isGridColumnVisible('Prospect/Client Name')" field="prospectName"
                        title="Prospect/Client Name" [width]="GridColumnWidth('Prospect/Client Name')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="cr-wd">
                                <a [routerLink]="'/prospects/viewprospect'"
                                    [queryParams]="{prospectId:dataItem.prospectId,listId: '0',initiated: 'appointment'}"
                                    title="" pTooltip="{{dataItem.prospectName}}" tooltipPosition="bottom"
                                    tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">{{
                                    dataItem.prospectName }}</a>
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                            </kendo-grid-string-filter-menu>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [hidden]="isGridColumnVisible('Opportunity Size')" field="opportunitySize"
                        title="Opportunity Size" [width]="GridColumnWidth('Opportunity Size')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex-div ph-no">
                                <span> {{dataItem?.opportunitySize==0 ? "" :
                                    (dataItem.opportunitySize | currency :
                                    'USD' : '$ ' :'1.0-0')
                                    }}</span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <number-range-filter [field]="column.field" [filter]="filter"
                                [filterService]="filterService" [format]="'n0'">
                            </number-range-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [hidden]="isGridColumnVisible('Status')" field="appointmentStatus" title="Status"
                        [width]="GridColumnWidth('Status')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex-div ph-no">
                                <span
                                    [ngClass]="{'approve': dataItem.status == 'Approved', 'decline':dataItem.status == 'Declined'}">
                                    {{dataItem.status}}
                                </span>
                            </div>
                        </ng-template>
                        <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                            <kendo-multiselect [checkboxes]="true" [(ngModel)]="gridFilterForArrayAppointmentStatus"
                                [autoClose]="false" [data]="appointmentStatusList" [valuePrimitive]="true"
                                (valueChange)="onappointmentStatusChange($event)">
                            </kendo-multiselect>
                        </ng-template> -->
                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="appointmentStatusList" [currentFilter]="filter"
                                [filterService]="filterService" [isPrimitive]="true"
                                [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
        <ng-container *ngIf="confirmationMsg == 'deleteModelClose'">
            <p-dialog [header]="titleRecall" [modal]="true" [(visible)]="_confirmBox.isVisible"
                [breakpoints]="{'960px': '75vw'}" [style]="{width: '30vw'}" [draggable]="false" [resizable]="false"
                [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
                <p>{{_confirmBox.message}}</p>
                <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                    <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary">
                    </p-button>
                    <p-button (click)="deleteAppointmentList()" label="Yes" styleClass="btn btn-primary"></p-button>
                </ng-template>
            </p-dialog>
        </ng-container>
        <p-dialog header="Alert" [modal]="true" [(visible)]="_confirmBox1.isVisible" [breakpoints]="{'960px': '75vw'}"
            [style]="{width: '40vw'}" [draggable]="false" [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox1"
            [closable]="false" (onHide)=" confirmBoxOk()">
            <p>{{_confirmBox1.message}}</p>

            <ng-template pTemplate="footer" *ngIf="_confirmBox1.hasFooter">
                <p-button (click)=" confirmBoxOk()" label="OK" styleClass="btn btn-primary">
                </p-button>
            </ng-template>
        </p-dialog>
    </div>
</div>
