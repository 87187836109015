import { Component, HostListener, OnInit } from '@angular/core';
import { OnDestroy, ViewChild } from '@angular/core';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { CustomNotifireService } from 'src/app/shared/services/view-prospect-notifire.service';
import { SelectedHeaderService } from 'src/app/shared/services/selected-header.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { CompositeFilterDescriptor, filterBy, SortDescriptor } from '@progress/kendo-data-query';
import { FilterExpression } from '@progress/kendo-angular-filter';
import { DataBindingDirective, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { debounceTime } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { ProfileInfoService } from 'src/app/shared/services/profile-info.service';
import { AddAppointmentComponent } from '../add-appointment/add-appointment.component';
import { AppointmentService } from '../../services/appointment.service';
import { EditAppointmentComponent } from '../edit-appointment/edit-appointment.component';
import { Title } from '@angular/platform-browser';
import { ProspectListService } from 'src/app/features/prospect/services/prospect-list.service';
import { GridColumnSelectionModalComponent } from 'src/app/features/list-management/components/grid-column-selection-modal/grid-column-selection-modal.component';
import { ManageListService } from 'src/app/features/list-management/services/ManageList.service';
import { UserManagementService } from 'src/app/features/user-management/services/user-management.service';

@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.css']
})
export class AppointmentListComponent implements OnInit, OnDestroy {
  filterData = JSON.parse(sessionStorage.getItem('appointmentFilterStorage')!);
  highlightedRow: number = 0;
  _navigationPath: NavigationPath;
  column: any;
  confirmationMsg: any;
  _userMessages: UserMessages;
  _confirmBox: ConfirmBox;
  _confirmBox1: ConfirmBox;
  data: any = {};
  id: any;
  contactName: any;
  currentDate = new Date();
  listTitle: string = 'All Prospects';
  errorList: any[] = [];
  userDetailes: any;
  allCols: any[] = [];
  @ViewChild('grid', { static: false }) public grid!: GridComponent;
  orderedCols: any[] = [];

  columnsOfList: any[] = [];

  // Telrik start
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  public buttonCount = 5;
  public sizes = [10, 25, 50, 100, 250, 500];
  skip = this.filterData != null ? (this.filterData.pageNumber - 1) * this.filterData.pageSize : 0;
  selectedListId = this.filterData != null ? this.filterData.listId : 0;
  pageSize = this.filterData != null ? this.filterData.pageSize : 100;
  pageNo: number = this.filterData != null ? this.filterData.pageNumber : 1;
  totalCount = this.filterData != null ? this.filterData.totalCount : 0;
  finalAppointmentList: any = [];
  public gridFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  filteredDataContactIds: any;
  selectedParPageOption: number = 0;

  eventStartDate: any;
  titleRecall: any;
  public recallList: any = [];
  public searchText: string = '';
  public defaultFilter: CompositeFilterDescriptor = { filters: [], logic: 'and' };
  public filterValue: CompositeFilterDescriptor = { ...this.defaultFilter };
  public masterRecallList: any[] = [];
  public isFilter: boolean = false;
  private $searchingText = new Subject<void>();
  public gridFilters: CompositeFilterDescriptor = { ...this.defaultFilter };

  public filters: FilterExpression[] = [
    {
      field: 'dateofAppointment',
      title: 'Date of Appointment',
      editor: 'date',
    },
    {
      field: 'startTime',
      title: 'Start Time',
      editor: 'date',
    },
    {
      field: 'endTime',
      title: 'End Time',
      editor: 'date',
    },
    {
      field: 'prospectName',
      title: 'Prospect',
      editor: 'string',
    },
    {
      field: 'contactName',
      title: 'Contact Name',
      editor: 'string',
    },
    {
      field: 'contactNumber',
      title: 'Contact Number',
      editor: 'string',
    },
    {
      field: 'createdBy',
      title: 'Created By',
      editor: 'string',
    },
  ];
  public sort: SortDescriptor[] = [
    {
      field: this.filterData != null ? this.filterData.sortCol : this.filters[0].field,
      dir: this.filterData != null ? this.filterData.sortDir : "asc",
    }
  ];
  calenderList: any;
  public selectedDate!: Date;
  startDate: any;
  endDate: any;
  showError: boolean = false;
  unAthorizesd: any = '';
  errorMessage: any;
  utcTime: any;
  eventTypeList: any;
  gridFilterForEventType: any;
  gridFilterArrayForEventType: any[] = [];
  gridFilterForAppointmentType: any;
  appointmentTypeList: any;
  appointmentStatusList: any;
  gridFilterForArrayAppointmentType: any[] = [];
  gridFilterForArrayAppointmentStatus: any[] = [];
  pinUnpinList: boolean = false;
  pinnedListId: any;
  selectedDefaultList = '0';
  // isValidUser: boolean = false;
  userId: any;
  isValidUser: boolean = false;
  isInValidNumber: boolean = false;
  selectResetArr: any = {};
  appointmentFilterJsonPayload: any
  columnWidthMaster: any = {}
  ColumnOrderMaster: any = []
  columnsOfListMasterData: any = [];
  reordered: boolean = false;
  removeFilterForhideColumn: boolean = false;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private _loaderService: LoaderService,
    private _selectedHeaderService: SelectedHeaderService,
    private _customNotifireService: CustomNotifireService,
    public activeModal: NgbActiveModal,
    private datePipe: DatePipe,
    private _datepipe: DatePipe,
    private _router: Router,
    private _preventBack: PreventBackButtonService,
    public profileInfoService: ProfileInfoService,
    private recallListService: AppointmentService,
    private titleService: Title,
    private _ProspectListService: ProspectListService,
    private _manageListService: ManageListService,
    private userManagementService: UserManagementService
  ) {
    this._userMessages = new UserMessages();
    this._confirmBox = new ConfirmBox();
    this._confirmBox1 = new ConfirmBox();
    this._navigationPath = new NavigationPath();
    this.$searchingText.pipe(
      debounceTime(1000)
    ).subscribe(() => {
      this.getAppointmentList();
    });
  }
  calendarView = false;
  recallDates: any;
  events: any[] = [];
  appointmentData: any[] = [];
  copyRecallData: any[] = [];
  appointmentData$!: Observable<any>;
  searchResult$!: Observable<any>;
  private destroy$ = new Subject();
  datavalue: any;
  options: any[] = [];
  finalOptions: any;
  hours: any;
  minutes: any;
  currentYear = new Date().getFullYear();
  endTime: any
  startTime: any

  @HostListener('document:keypress', ['$event'])
  startSearch(event: KeyboardEvent) {
    if (event.code === "Enter") {
      this.onFilter()
    }
  }
  ngOnInit(): void {
    this.titleService.setTitle('Appointments');
    this.profileInfoService.statusCode.subscribe((res: any) => {
      this.unAthorizesd = res ? res : '';
    })
    this._preventBack.preventBackButton(); //method to prevent browser back button
    this.userDetailes = JSON.parse(localStorage.getItem('userDetails')!);
    if (this.userDetailes) {
      if (!this.filterData) {
        this.selectedListId = this.userDetailes.user.defaultAppointmentListId;
      }
      this.userId = this.userDetailes.user.userId;
      this.pinnedListId = this.userDetailes.user.defaultAppointmentListId;

      this.pinUnpinList = this.pinnedListId == 0 ? false : true;
      if (this.pinnedListId == this.selectedListId) {
        this.pinUnpinList = true;
      }
    }
    // if (this.filterData?.gridFilterJson == '' || this.filterData?.gridFilterJson == null) {
    //   this.getAppointmentList();
    // } else {
    //   this.getAppointmentListUsingFilterStorage()
    // }
    // this.userInformation();
    this._customNotifireService.setActiveComponent('Appointments');
    this.searchResult$ = this._selectedHeaderService.getSelectedHeader();
    this.searchResult$.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      this.datavalue = data;
    });
    this.eventTypeList = ['Event / Conference', 'Prospect Meeting', 'Client Meeting'];
    this.appointmentTypeList = ['In Person', 'Virtual'];
    this.appointmentStatusList = ['Awaiting approval', 'Approved', 'Declined'];
    //if grid filter applied then kendo filter is opened on load
    this.isFilter = true;
    // setTimeout(() => {
    this.getDynamicColumnList();
    // }, 1000);
    this.userRoleChecked()
  }

  closeError() {
    this.showError = false;
    this.unAthorizesd = '';
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.gridFilters = { ...filter };
    this.selectResetArr["columnFilter"] = true;
    if (this.gridFilters.filters.length > 0) {
      var typeofVisitIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "typeofVisit")!
      if (typeofVisitIndex > -1 && this.gridFilterForArrayAppointmentType.length == 0)
        this.gridFilters.filters.splice(typeofVisitIndex, 1)
      if (typeofVisitIndex < 0) this.gridFilterForArrayAppointmentType = [];

      var typeofEventNameIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "typeofEventName")!
      if (typeofEventNameIndex > -1 && this.gridFilterArrayForEventType.length == 0)
        this.gridFilters.filters.splice(typeofEventNameIndex, 1)
      if (typeofEventNameIndex < 0) this.gridFilterArrayForEventType = [];

      var appointmentIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "appointmentStatus")!
      if (appointmentIndex > -1 && this.gridFilterForArrayAppointmentStatus.length == 0)
        this.gridFilters.filters.splice(appointmentIndex, 1)
      if (appointmentIndex < 0) this.gridFilterForArrayAppointmentStatus = [];
    }
    if (!this.gridFilters?.filters.length) {
      this.gridFilterForArrayAppointmentType = [];
      this.gridFilterArrayForEventType = [];
      this.gridFilterForArrayAppointmentStatus = [];
    }
    this.resetOptions();
    if (this.gridFilterArrayForEventType != undefined && this.gridFilterArrayForEventType.length > 0)
      this.onEventTypeChange(this.gridFilterArrayForEventType);

    if (this.gridFilterForArrayAppointmentType != undefined && this.gridFilterForArrayAppointmentType.length > 0)
      this.onappointmentTypeChange(this.gridFilterForArrayAppointmentType);
    if (this.gridFilterForArrayAppointmentStatus != undefined && this.gridFilterForArrayAppointmentStatus.length > 0)
      this.onappointmentStatusChange(this.gridFilterForArrayAppointmentStatus);
    // this.$searchingText.next();
    this.getAppointmentList(true);
  }

  public localToUtc(date: Date): Date {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }

  public onSearch(inputValue: any): void {
    this.searchText = inputValue;
    this.resetOptions();
    this.$searchingText.next();
  }

  searchValue(inputValue: any) {
    this.searchText = inputValue;
  }

  public onFilter(): void {
    let data = JSON.parse(sessionStorage.getItem('appointmentFilterStorage')!)
    if (this.searchText === "" && data?.searchText === "") {
      return
    }
    this.resetOptions();
    // this.$searchingText.next();
    this.getAppointmentList(true);
  }

  resetOptions(): void {
    this.pageNo = 1;
    this.skip = 0;
    this.totalCount = 0;
  }

  checkData(value: CompositeFilterDescriptor) {
    return JSON.stringify(value) === JSON.stringify(this.defaultFilter) ? "" : JSON.stringify(value);
  }

  convertingToDateType() {
    this.appointmentData.map((obj: any) => {
      if (obj.dateofAppointment) {
        obj.dateofAppointment = new Date(obj.dateofAppointment);
      }
    })
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.skip = 0;
    this.pageNo = 1;
    this.sort = sort;
    this.totalCount = this.finalAppointmentList.total;
    this.selectResetArr["sorting"] = true;
    this.getAppointmentList(true);
  }

  paginationChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.pageNo = (event.take + event.skip) / event.take;
    this.totalCount = this.finalAppointmentList.total;
    this.getAppointmentList(true);
  }

  // Telrik functions end
  parseAdjust(eventDate: string) {
    const date = new Date(eventDate);
    date.setFullYear(this.currentYear);
    return date;
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  getAppointmentList(saveInDB = false) {
    this.convertDateStringInFormatting(this.gridFilters);
    //time conversion to local to utc for send grid filter value
    let temGridFilterForTime = JSON.parse(JSON.stringify(this.gridFilters));
    temGridFilterForTime?.filters?.forEach((x: any) => {
      x?.filters?.forEach((obj: any) => {
        if (obj.field == 'startTime') {
          if (obj.value.length != 2) {
            var recallDate: any = new Date;
            if (obj.value.length < 2) obj.value = '0' + obj.value.trim() + ':00';
            if (obj.value.length > 2) {
              let valueTime = obj.value.split(' ');
              if (valueTime[0].length < 3) obj.value = valueTime[0].trim() + ':00' + ' ' + valueTime[1]
            }
            if (obj.value.length == 2) obj.value = obj.value.trim() + ':00';
            recallDate = this.datePipe.transform(recallDate, 'YYYY-MM-dd')
            var dateTime = recallDate + ' ' + obj.value
            var newDate = new Date(dateTime);
            // var utcDate = this.localToUtc(newDate);
            obj.value = this.datePipe.transform(newDate, 'HH:mm:ss') + '.0000000'
          } else {
            if (obj.value.length < 2) obj.value = '0' + obj.value.trim() + ':00';
            if (obj.value.length > 2) {
              let valueTime = obj.value.split(' ');
              if (valueTime[0].length < 3) obj.value = valueTime[0].trim() + ':00' + ' ' + valueTime[1]
            }
            if (obj.value.length == 2) obj.value = obj.value.trim() + ':00';
            var recallDate: any = new Date;
            recallDate = this.datePipe.transform(recallDate, 'YYYY-MM-dd')
            var dateTime = recallDate + ' ' + obj.value;

            // var recallTime = obj.value;
            // var dateTime = 22 - 10 - 22 + ' ' + recallTime + ':00 ';
            var newDate = new Date(dateTime);
            // var utcDate = this.localToUtc(newDate);
            obj.value = this.datePipe.transform(newDate, 'HH:mm:ss') + '.0000000'
          }
        }
        if (obj.field == 'endTime') {
          if (obj.value.length != 2) {
            // var endTime = obj.value;
            // var dateTime = 22 - 10 - 22 + ' ' + endTime
            var recallDate: any = new Date;
            if (obj.value.length < 2) obj.value = '0' + obj.value.trim() + ':00';
            if (obj.value.length > 2) {
              let valueTime = obj.value.split(' ');
              if (valueTime[0].length < 3) obj.value = valueTime[0].trim() + ':00' + ' ' + valueTime[1]
            }
            if (obj.value.length == 2) obj.value = obj.value.trim() + ':00';
            recallDate = this.datePipe.transform(recallDate, 'YYYY-MM-dd')
            var dateTime = recallDate + ' ' + obj.value

            var newDate = new Date(dateTime);
            // var utcDate = this.localToUtc(newDate);
            obj.value = this.datePipe.transform(newDate, 'HH:mm:ss') + '.0000000'
          } else {
            // var recallTime = obj.value;
            // var dateTime = 22 - 10 - 22 + ' ' + recallTime + ':00 ';
            if (obj.value.length < 2) obj.value = '0' + obj.value.trim() + ':00';
            if (obj.value.length > 2) {
              let valueTime = obj.value.split(' ');
              if (valueTime[0].length < 3) obj.value = valueTime[0].trim() + ':00' + ' ' + valueTime[1]
            }
            if (obj.value.length == 2) obj.value = obj.value.trim() + ':00';
            var recallDate: any = new Date;
            recallDate = this.datePipe.transform(recallDate, 'YYYY-MM-dd')
            var dateTime = recallDate + ' ' + obj.value;

            var newDate = new Date(dateTime);
            // var utcDate = this.localToUtc(newDate);
            obj.value = this.datePipe.transform(newDate, 'HH:mm:ss') + '.0000000'
          }
        }
      })
    });
    let request = {
      sortDir: this.sort[0].dir,
      sortCol: this.sort[0].field,
      filterJson: this.checkData(this.filterValue),
      searchText: this.searchText,
      pageNumber: this.pageNo,
      pageSize: this.pageSize,
      //gridFilterJson: this.checkData(this.gridFilters),
      gridFilterJson: temGridFilterForTime.filters != null && temGridFilterForTime.filters.length > 0 ? JSON.stringify(temGridFilterForTime) : "",
      totalCount: this.totalCount,
      userId: this.userId,
      listId: this.selectedListId
    }
    this.calendarView = false;
    sessionStorage.setItem('appointmentFilterStorage', JSON.stringify(request));
    if (saveInDB) {
      this.appointmentFilterJsonPayload = JSON.stringify(request);
      this.saveUserMasterGridColumn();
    }
    this.appointmentData$ = this.recallListService.getAppointmentList(request);
    this.appointmentData$.pipe(takeUntil(this.destroy$)).subscribe(
      (resp: any) => {
        request.totalCount = resp.totalRecord;
        sessionStorage.setItem('appointmentFilterStorage', JSON.stringify(request));
        this.selectedDefaultList = this.selectedListId;
        this.gridFilters = this.convertFormattingInDateString(this.gridFilters);
        if (resp) {
          this.appointmentData = resp.items[0].appointments;
          this.finalAppointmentList = {
            data: this.appointmentData,
            total: resp.totalRecord,
          };
          this.finalAppointmentList.data.forEach((element: any) => {
            var dateValue = this._datepipe.transform(element.dateofAppointment, 'yyyy-MM-dd')
            // var dateValue = element.dateofAppointment
            // var setTimeForStart = this._datepipe.transform(element.startTime, 'HH:mm:ss')
            // var setTimeForEnd = this._datepipe.transform(element.endTime, 'HH:mm:ss')
            if (element.startTime) {
              var dt = dateValue + ' ' + element.startTime;
              var newDate = new Date(dt);
              // var utcDate = this.utcToLocal(newDate);
              // var utcDate = this.utcToLocal(newDate);
              // this.startTime = this._datepipe.transform(newDate, 'HH:mm:ss')
              this.startTime = element.startTime
            }

            if (element.endTime) {
              var endTime = dateValue + ' ' + element.endTime;
              var newDate = new Date(endTime);
              var utcDate = this.utcToLocal(newDate)
              // var dateValue1 = this._datepipe.transform(utcDate, 'yyyy-MM-dd')
              // this.endTime = this._datepipe.transform(newDate, 'HH:mm:ss')
              this.endTime = element.endTime
              // this.completeTimeForEnd = dateValue + 'T' + setTime + 'Z';
            }
            const time_part_array = this.startTime.split(':');
            const time_part_array1 = this.endTime.split(':');
            let ampm = 'AM';
            let ampm1 = 'AM';
            if (time_part_array[0] >= 12) {
              ampm1 = 'PM';
            }
            if (time_part_array1[0] >= 12) {
              ampm = 'PM';
            }
            if (time_part_array[0] > 12) {
              time_part_array[0] = time_part_array[0] - 12;
              if (time_part_array[0] < 10) {
                time_part_array[0] = '0' + time_part_array[0];
              }
            }
            if (time_part_array1[0] > 12) {
              time_part_array1[0] = time_part_array1[0] - 12;
              if (time_part_array1[0] < 10) {
                time_part_array1[0] = '0' + time_part_array1[0];
              }
            }
            element.startTime =
              time_part_array[0] + ':' + time_part_array[1] + ' ' + ampm1;
            element.endTime =
              time_part_array1[0] + ':' + time_part_array1[1] + ' ' + ampm;
          });
          console.log(" Recall List after loop", this.finalAppointmentList);
          if (this.datavalue === 'homeRecall') {
            let event: any = {
              preventDefault: () => { },
            };
          }
        }

      },
      (err: any) => {
        this.gridFilters = this.convertFormattingInDateString(this.gridFilters);
        if (err.error.status == 403) {
          this.showError = true;
        }
        else {
          let errorMessage =
            'Error : ' + err.message + ', Status: ' + err.status;
          this.errorList = [];
          this.showError = true;
          this.errorList.push(errorMessage);
        }
      }
    );
  }

  getAppointmentListUsingFilterStorage() {
    // this.filterData = JSON.parse(sessionStorage.getItem('appointmentFilterStorage')!);
    let data
    if (this.appointmentFilterJsonPayload) {
      data = JSON.parse(this.appointmentFilterJsonPayload);
    }
    sessionStorage.setItem('appointmentFilterStorage', JSON.stringify(data));
    this.recallListService.getAppointmentList(data).subscribe(
      (resp) => {
        if (resp) {
          this.appointmentData = resp.items[0].appointments;
          if (this.appointmentData) {
            this.appointmentData = resp.items[0].appointments;
            this.finalAppointmentList = {
              data: this.appointmentData,
              total: resp.totalRecord,
            };
            this.finalAppointmentList.data.forEach((element: any) => {
              var dateValue = this._datepipe.transform(element.dateofAppointment, 'yyyy-MM-dd')
              // var dateValue = element.dateofAppointment
              // var setTimeForStart = this._datepipe.transform(element.startTime, 'HH:mm:ss')
              // var setTimeForEnd = this._datepipe.transform(element.endTime, 'HH:mm:ss')
              if (element.startTime) {
                var dt = dateValue + ' ' + element.startTime;
                var newDate = new Date(dt);
                // var utcDate = this.utcToLocal(newDate);
                // this.startTime = this._datepipe.transform(newDate, 'HH:mm:ss')
                this.startTime = element.startTime
              }

              if (element.endTime) {
                var endTime = dateValue + ' ' + element.endTime;
                var newDate = new Date(endTime);
                // var utcDate = this.utcToLocal(newDate)
                // var dateValue1 = this._datepipe.transform(utcDate, 'yyyy-MM-dd')
                // this.endTime = this._datepipe.transform(newDate, 'HH:mm:ss')
                this.endTime = element.endTime;

                // this.completeTimeForEnd = dateValue + 'T' + setTime + 'Z';
              }
              const time_part_array = this.startTime.split(':');
              const time_part_array1 = this.endTime.split(':');
              let ampm = 'AM';
              if (time_part_array[0] >= 12) {
                ampm = 'PM';
              }
              if (time_part_array1[0] >= 12) {
                ampm = 'PM';
              }
              if (time_part_array[0] > 12) {
                time_part_array[0] = time_part_array[0] - 12;
                if (time_part_array[0] < 10) {
                  time_part_array[0] = '0' + time_part_array[0];
                }
              }
              if (time_part_array1[0] > 12) {
                time_part_array1[0] = time_part_array1[0] - 12;
                if (time_part_array1[0] < 10) {
                  time_part_array1[0] = '0' + time_part_array1[0];
                }
              }
              element.startTime =
                time_part_array[0] + ':' + time_part_array[1] + ' ' + ampm;
              element.endTime =
                time_part_array1[0] + ':' + time_part_array1[1] + ' ' + ampm;
            });
            this.setDataUsingFilterStorage();
            this._loaderService.hide();
          }
        }
      },
      (err: any) => {
        this.showError = true;
        this.errorMessage =
          'Error : ' + err.message + ', Status: ' + err.status;
        this.errorList = [];
        this.errorList.push(this.errorMessage);
        this._loaderService.hide();
      }
    );
  }

  columnResize(event: any) {
    this.selectResetArr["width"] = true;
    this.columnsOfList.forEach(x => {
      if (x.columnName.toLowerCase() == event[0].column.title.toLowerCase()) {
        x.width = event[0].newWidth;
      }
    })
    let columnList = { viewColumns: this.columnsOfList, columnOrder: this.orderedCols }
    let opts = {

      listId: this.selectedListId,
      module: "Appointment",
      columnList: columnList,
      filterJson: this.appointmentFilterJsonPayload,

    }
    this.userManagementService.saveGridFilterColumnList(opts).subscribe(s => {
      // this.getDynamicColumnList();
    })

  }

  setDataUsingFilterStorage() {
    // this.filterData = JSON.parse(sessionStorage.getItem('appointmentFilterStorage')!)
    if (this.filterData) {
      let stringToJsongridfilter = this.filterData.gridFilterJson ? JSON.parse(this.filterData?.gridFilterJson) : '';
      let objStartTime;
      let objEndTime;
      if (stringToJsongridfilter) {
        stringToJsongridfilter.filters?.forEach((x: any) => {
          x?.filters?.forEach((obj: any) => {
            if (obj.field == 'startTime') {
              if (obj.value.length != 2) {
                var startTime = obj.value;
                var dateTime = 22 - 10 - 22 + ' ' + startTime
                var newDate = new Date(dateTime);
                var utcDate = this.utcToLocal(newDate);
                obj.value = this.datePipe.transform(newDate, 'HH:mm:ss');
                objStartTime = obj.value;
                // let indexForStart = stringToJsongridfilter.filters.findIndex((x: any) => x.field == "startTime");
                // stringToJsongridfilter.filters[indexForStart].value = obj.value;
              } else {
                var startTime = obj.value;
                var dateTime = 22 - 10 - 22 + ' ' + recallTime + ':00 ';
                var newDate = new Date(dateTime);
                var utcDate = this.utcToLocal(newDate);
                obj.value = this.datePipe.transform(newDate, 'HH:mm:ss');
                objStartTime = obj.value;
                // let indexForStart = stringToJsongridfilter.filters.findIndex((x: any) => x.field == "startTime")
                // stringToJsongridfilter.filters[indexForStart].value = obj.value;
              }
              const time_part_array = this.startTime?.split(':');
              let ampm = 'AM';
              if (time_part_array) {
                if (time_part_array[0] >= 12) {
                  ampm = 'PM';
                }
                if (time_part_array[0] > 12) {
                  time_part_array[0] = time_part_array[0] - 12;
                  if (time_part_array[0] < 10) {
                    time_part_array[0] = '0' + time_part_array[0];
                  }
                }
                obj.value =
                  time_part_array[0] + ':' + time_part_array[1] + ' ' + ampm;
              }
            }

            if (obj.field == 'endTime') {
              if (obj.value.length != 2) {
                var endTime = obj.value;
                var dateTime = 22 - 10 - 22 + ' ' + endTime
                var newDate = new Date(dateTime);
                var utcDate = this.utcToLocal(newDate);
                obj.value = this.datePipe.transform(newDate, 'HH:mm:ss')
                objEndTime = obj.value;
                // let indexForEnd = stringToJsongridfilter.filters.findIndex((x: any) => x.field == "endTime")
                // stringToJsongridfilter.filters[indexForEnd].value = obj.value;
              } else {
                var recallTime = obj.value;
                var dateTime = 22 - 10 - 22 + ' ' + recallTime + ':00 ';
                var newDate = new Date(dateTime);
                var utcDate = this.utcToLocal(newDate);
                obj.value = this.datePipe.transform(newDate, 'HH:mm:ss')
                objEndTime = obj.value;
                // let indexForEnd = stringToJsongridfilter.filters.findIndex((x: any) => x.field == "endTime")
                // stringToJsongridfilter.filters[indexForEnd].value = obj.value;
              }
              const time_part_array1 = this.endTime?.split(':');
              let ampm = 'AM';
              if (time_part_array1) {
                if (time_part_array1[0] >= 12) {
                  ampm = 'PM';
                }
                if (time_part_array1[0] > 12) {
                  time_part_array1[0] = time_part_array1[0] - 12;
                  if (time_part_array1[0] < 10) {
                    time_part_array1[0] = '0' + time_part_array1[0];
                  }
                }
                obj.value =
                  time_part_array1[0] + ':' + time_part_array1[1] + ' ' + ampm;
              }
            }
          })
          this.filterData.gridFilterJson = JSON.stringify(stringToJsongridfilter);
        })
      }
      //localStorage.setItem('appointmentFilterStorage', this.filterData)!
      if (this.filterData) {
        if (this.filterData.filterJson) {
          this.filterValue = JSON.parse(this.filterData.filterJson)
        }

        this.gridFilters = this.filterData.gridFilterJson ? this.convertFormattingInDateString(JSON.parse(this.filterData.gridFilterJson)) : "";
        this.searchText = this.filterData.searchText;

        if (this.filterData.gridFilterJson) {
          this.gridFilterForEventType = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "typeofEventName")[0]?.value;
          this.gridFilterArrayForEventType = this.gridFilterForEventType?.split(',');
          this.gridFilterForAppointmentType = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "typeofVisit")[0]?.value;
          this.gridFilterForArrayAppointmentType = this.gridFilterForAppointmentType?.split(',');
          let gridFilterForAppointmentStatus = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "appointmentStatus")[0]?.value;
          this.gridFilterForArrayAppointmentStatus = gridFilterForAppointmentStatus?.split(',');

        }
      }
    }
  }


  //To open 'Add Recall' Modal
  openAddappointmentModal() {
    this.highlightedRow = 0;
    const modalRef = this.modalService.open(AddAppointmentComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp == 'Add Appointment') {
        this.getAppointmentList()
      }
    })
    modalRef.closed.subscribe((resp) => {
    });
  }


  formatPhoneNumber(phoneNumberString: any) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }


  confirmBoxCancel(type: any) {
    if (type == 'modelClose') {
      this._confirmBox.isVisible = false;
      this.activeModal.dismiss();
    }
    if (type == 'override') {
      this._confirmBox.isVisible = false;
    }
  }

  //To hide column filters
  columnFilter(value: boolean) {
    this.isFilter = !value;
  }

  //to open 'Edit Appointment' Modal
  openEditAppointmentModel(item: any) {
    const modalRef = this.modalService.open(EditAppointmentComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    //let data = item;
    modalRef.componentInstance.fromAppointmentList = item.id;
    modalRef.componentInstance.prospectIdfromAppointmentList = item.prospectId;
    modalRef.componentInstance.userId = this.userId;
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp == 'Edit Appointment') {
        this.getAppointmentList();
      }
    })
    modalRef.closed.subscribe((resp) => {
    });
  }

  //to cancel the process and back to prospect list screen
  openDeleteModel(id: any) {
    this.confirmationMsg = 'deleteModelClose'
    this.titleRecall = 'Confirmation'
    this._confirmBox.message = this._userMessages.cancelAppointmentMessage;
    this._confirmBox.isVisible = true;
    this.id = id;
  }

  deleteAppointmentList() {
    this.recallListService.deleteAppointmentList(this.id).subscribe(resp => {
      this.datavalue = '';
      this._confirmBox.isVisible = false;
      this.activeModal.close();
      this.getAppointmentList();
    })
  }

  public utcToLocal(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }

  //for grid column on Event Type Change
  public onEventTypeChange(event: any): void {
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "typeofEventName")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "typeofEventName",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "typeofEventName",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }


  //for grid column on Appointment Type Change
  public onappointmentTypeChange(event: any): void {
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "typeofVisit")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "typeofVisit",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "typeofVisit",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  //for grid column on Appointment status Change
  public onappointmentStatusChange(event: any): void {
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "appointmentStatus")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "appointmentStatus",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "appointmentStatus",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }
  updateUserForProspect() {
    this.pinUnpinList = this.selectedListId == 0 ? false : true;
    if (this.pinnedListId == this.selectedListId) {
      this.pinUnpinList = false;
      this.pinnedListId = 0;
    } else {
      this.pinUnpinList = true;
      this.pinnedListId = this.selectedListId;
    }
    this.userDetailes = JSON.parse(localStorage.getItem('userDetails')!);
    this.userDetailes.user.defaultAppointmentListId = this.pinnedListId;
    localStorage.setItem('userDetails', JSON.stringify(this.userDetailes));
    let saveData = {
      "listId": this.pinnedListId == 0 ? 0 : this.selectedListId,
      "entityType": "appointment",
      "userId": this.userId
    }
    this._ProspectListService.updateUserForProspect(saveData).subscribe((resp: any) => {
      if (resp) {

      }
    })
  }
  onCreateListChange(event: any, callfrom: any) {
    sessionStorage.removeItem('appointmentFilterStorage');
    this.gridFilterArrayForEventType = [];
    this.gridFilterForArrayAppointmentType = [];

    this.selectedDefaultList = event.target.value;
    this.selectedListId = event.target.value;
    if (this.pinnedListId == event.target.value) {
      this.pinUnpinList = true;
    }
    else {
      this.pinUnpinList = false;
    }

    this.pageNo = 1;
    this.skip = 0;
    this.totalCount = 0;
    this.searchText = "";
    this.defaultFilter = { logic: 'and', filters: [] };
    this.filterValue = { logic: 'and', filters: [] };
    this.gridFilter = { logic: 'and', filters: [], };
    this.gridFilters = { logic: 'and', filters: [], };

    //this.getAppointmentList(true);
    // uncomment when ur adding column menu option
    // setTimeout(() => {
    this.getDynamicColumnList();
    // }, 1000);
    if (this.reordered) this.reloadComponent(true);
  }
  onColumnMenuInit(e: any) {
    this.removeFilterForhideColumn = false;
    for (let i = 0; i < e.columns.length; i++) {
      this.gridFilters?.filters?.forEach((x: any, index: any) => {
        x?.filters?.forEach((z: any) => {
          if (z.field.toLowerCase() == e.columns[i].field.toLowerCase() && e.columns[i].hidden) {
            this.removeFilterForhideColumn = true;
            this.isInValidNumber = true;
            this._confirmBox1.message = "Hiding a column with a filter applied will clear the filter.";
            this._confirmBox1.isVisible = true;
            this.gridFilters?.filters.splice(index, 1)
            if (this.gridFilters?.filters.length == 0)
              (document.getElementById('columnFilter') as HTMLInputElement).checked = false;
          }
        })
      })
    }
    let currentColumns = this.allCols || [];
    for (let i = 0; i < e.columns.length; i++) {
      currentColumns.forEach((col: any) => {
        if (col.columnName.toLowerCase() == e.columns[i].title.toLowerCase()) {
          col.isVisible = !col.isVisible
        }
      })
    }

    this.columnsOfList = currentColumns;
    let columnList = { viewColumns: currentColumns, columnOrder: this.orderedCols }
    let opts = {

      listId: this.selectedListId,
      module: "Appointment",
      columnList: columnList,
      filterJson: this.appointmentFilterJsonPayload
    }
    this.selectResetArr["column"] = true;

    if (!this.removeFilterForhideColumn) this.userManagementService.saveGridFilterColumnList(opts).subscribe(s => { })
  }

  confirmBoxOk() {
    this._confirmBox1.isVisible = false;
    this.isInValidNumber = false;
    if (this.removeFilterForhideColumn) {
      this.skip = 0;
      this.pageNo = 1;
      this.totalCount = 0;
      this.getAppointmentList(true)
    }
  }

  onColumnReorder(event: any) {

    if (event.newIndex < 1) {
      event.preventDefault();
      return;
    }
    // Save column order to local storage when columns are reordered
    this.selectResetArr["columnSequence"] = true;
    this.reordered = true;
    // Save column order to local storage when columns are reordered

    let columnOrder1 = this.moveElementInArray(this.orderedCols, event.oldIndex, event.newIndex);
    this.orderedCols = columnOrder1;
    this.saveUserMasterGridColumn();


    //localStorage.setItem("columnOrder", JSON.stringify(columnOrder));
  }

  setFilterData() {
    if (this.appointmentFilterJsonPayload) {
      this.filterData = JSON.parse(this.appointmentFilterJsonPayload);
      if (this.filterData?.gridFilterJson) this.gridFilters = JSON.parse(this.filterData?.gridFilterJson);
      // if (this.filterData?.filterJson) this.filterValue = JSON.parse(this.filterDataRecall?.filterJson);
      // if (this.filterDataRecall?.filterJson) this.filterValueCopy = JSON.parse(this.filterDataRecall?.filterJson);
      if (this.filterData?.sortCol) this.sort = [{ field: this.filterData?.sortCol, dir: this.filterData.sortDir, }];
      // if (this.filterValue.filters.length > 0) this.isFilter1 = true
      if (this.filterData?.pageNumber) {
        this.pageNo = this.filterData != null ? this.filterData?.pageNumber : 1;
        this.skip = this.filterData != null ? (this.filterData?.pageNumber - 1) * this.filterData?.pageSize : 0;
        this.pageSize = this.filterData != null ? this.filterData?.pageSize : 100;
      }
    } else {
      this.gridFilters = { ...this.defaultFilter };
      this.filterValue = { ...this.defaultFilter };
      // this.filterValueCopy = { ...this.defaultFilter };
      this.sort = [{ field: 'dateofAppointment', dir: 'asc', }];
      if (this.filterData) this.filterData.searchText = '';
      this.pageNo = 1;
      this.skip = 0;
      this.pageSize = 100;
    }
  }

  getDynamicColumnList() {

    this._manageListService.getDynamicColumnList(this.selectedListId, 'Appointment').subscribe((resp: any) => {
      if (resp) {

        console.log(resp)
        this.appointmentFilterJsonPayload = resp.filterJson;
        this.setFilterData();
        if (this.appointmentFilterJsonPayload) {
          this.setDataUsingFilterStorage();
          this.getAppointmentListUsingFilterStorage()
        }
        else {
          this.setFilterData();
          this.getAppointmentList();
        }
        if (resp.selectedViewColumns && resp.selectedViewColumns.viewColumns && (resp.selectedViewColumns.viewColumns || []).length > 0) {
          this.columnsOfList = resp.selectedViewColumns.viewColumns;
        }
        else {
          this.columnsOfList = resp.masterViewColumns.viewColumns;
        }

        if (resp.selectedViewColumns && resp.selectedViewColumns.columnOrder && (resp.selectedViewColumns.columnOrder || []).length > 0) {
          this.orderedCols = resp.selectedViewColumns.columnOrder;
        }
        else {
          this.orderedCols = resp.masterViewColumns.columnOrder;
        }

        this.ColumnOrderMaster = resp.masterViewColumns.columnOrder
        resp.masterViewColumns.viewColumns.forEach((column: any) => {
          this.columnWidthMaster[column.columnName] = column.width;
        });

        this.columnsOfListMasterData = resp.masterViewColumns.viewColumns;


        this.selectResetArr = {};
        this.selectedDefaultList = this.selectedListId;
        if (this.filterData) {
          if (this.gridFilters?.filters?.length) this.selectResetArr["columnFilter"] = true;
          // if (this.selectedListId == 0 && this.filterValueCopy?.filters?.length) { this.selectResetArr["filter"] = true; this.isFilter1 = false }
          if (!(this.filterData && this.filterData?.sortCol == 'dateofAppointment' && this.filterData.sortDir == 'asc')) this.selectResetArr["sorting"] = true;
        }
        if (this.compareVisibility(this.columnsOfList, this.columnsOfListMasterData)) this.selectResetArr["column"] = true;

        if (!this.arraysAreIdentical(this.orderedCols, this.ColumnOrderMaster)) this.selectResetArr["columnSequence"] = true;
        if (!this.areWidthsEqual(this.columnsOfList, this.columnWidthMaster)) this.selectResetArr["width"] = true;

        this.allCols = this.columnsOfList

        let cols: any[] = [];
        const columns = this.grid?.columns?.toArray();
        cols = columns?.slice(0, 1).concat(cols) || [];

        if ((this.orderedCols || []).length > 0) {
          const columns = this.grid?.columns?.toArray().splice(1); // Assuming you want to start from the third column
          let reorderedColumns1: any[] = [];

          this.orderedCols.forEach((title: any) => {
            const column = columns?.find(item => {
              //console.log("Title:", title);
              //console.log("Column Title:", item.title);
              return item.title === title;
            });
            if (column) {
              reorderedColumns1.push(column);
            }
          });
          cols = cols?.concat(reorderedColumns1);

          //console.log("rC1", reorderedColumns1);
          this.grid?.columns?.reset(cols);
        }

      }
    },
      (err: any) => {
        this.showError = true;
        this.errorMessage =
          'Error : ' + err.message + ', Status: ' + err.status;
        this.errorList = [];
        this.errorList.push(this.errorMessage);
      }
    );
  }
  moveElementInArray(array: any, oldIndex: number, newIndex: number) {
    if (oldIndex === newIndex || oldIndex < 0 || newIndex < 0 || oldIndex >= array.length || newIndex >= array.length) {
      return; // No need to move if old and new index are the same or out of bounds
    }

    const elementToMove = array.splice(oldIndex, 1)[0]; // Remove the element from the old index
    // console.log(elementToMove)
    array.splice(newIndex, 0, elementToMove);
    //console.log(array)
    return array; // Insert the element at the new index
  }
  isGridColumnVisible(colName: string): boolean {

    let retVal: boolean = true;


    try {
      if (this.columnsOfList.length > 0) {
        let columnInfo = this.columnsOfList.find((x: any) => x.columnName.toLowerCase() === colName.toLowerCase() && x.isVisible == true)
        if (columnInfo != undefined) {
          retVal = !columnInfo.isVisible;
        }
        else {
          retVal = true;

        }
      }
    }
    catch (e) {
      retVal = false
    }

    return retVal

  }
  userRoleChecked() {
    let userDetailsData = JSON.parse(localStorage.getItem('userDetails')!)
    if (userDetailsData) {
      userDetailsData?.user?.roles.forEach((x: any) => {
        if (x.roleName == 'Admin' || x.roleName == 'Sales Director' || x.roleName == 'Client Service Level 4') {
          this.isValidUser = true;
        }
      })
    }
  }
  exportGlobal(): void {
    const modalRef = this.modalService.open(GridColumnSelectionModalComponent, { modalDialogClass: 'listDialog' });
    modalRef.componentInstance.listId = 0;
    let indexProspectId = this.allCols.findIndex((x: any) => x.columnName == 'ProspectID');

    //if (indexProspectId > -1) this.allCols.splice(indexProspectId, 1);
    //let indexContactId = this.allCols.findIndex((x: any) => x.columnName == 'RecallID');
    //alert(indexContactId)
    //if (indexContactId > -1) this.allCols.splice(indexContactId, 1);
    console.log(this.allCols)
    modalRef.componentInstance.allCols = this.allCols;
    modalRef.componentInstance.disabledCols = 'ProspectId,ContactId,AppointmentId';

    modalRef.result.then((result) => {
      if (result) {
        // Data sent back from modal
        let listId = this.selectedListId;
        let finalArr: any[] = ["ContactId"];
        let arr: any = []
        arr = result.split(',');
        arr.forEach((x: any) => {
          finalArr.push(x);
        })
        if (result == '')
          finalArr = [];

        let data: any = {
          appointmentId: this.selectedListId,
          columnList: finalArr,
        };
        // console.log(data);

        this._manageListService.getExportAppointmentList(data).pipe().subscribe((success: any) => {
          if (success) {
            this.isInValidNumber = true;
            this._confirmBox1.message = this._userMessages.exportListMessage;
            this._confirmBox1.isVisible = true;
          }
        })
      }
    });


    // userInformation() {
    //   let userDetailsData = JSON.parse(localStorage.getItem('userDetails')!)
    //   if (userDetailsData) {
    //     userDetailsData?.user?.roles.forEach((x: any) => {
    //       if (x.roleName == 'Admin') {
    //         this.isValidUser = true;
    //       }
    //     })
    //   }
    // }
  }

  DownloadExportFile(exportType: any): void {
    if (exportType == 'All') {
      let finalArr = ["ProspectID", "ContactID", "AppointmentID"]
      let _orderedCols: any = []
      this.orderedCols.forEach((y: any) => {
        this.columnsOfList.forEach((x: any) => {
          if (x.isVisible) {
            if (x.columnName != "Actions" && x.columnName != null) {
              if (y == x.columnName)
                _orderedCols.push(x.columnName);
            }
          }
        })
      })
      finalArr = finalArr.concat(_orderedCols)
      let data: any = {
        listId: this.selectedListId,
        columnList: finalArr,
        entityType: "appointment",
        appliedFilter: this.appointmentFilterJsonPayload
      };

      this._manageListService.getExportAppointmentList(data).pipe().subscribe((success: any) => {
        if (success) {
          this.isInValidNumber = true;
          this._confirmBox1.message = this._userMessages.exportListMessage;
          this._confirmBox1.isVisible = true;
        }
      })
    }





  }
  convertDateStringInFormatting(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertDateStringInFormatting(x)
        }
        else {
          if (x.field == 'dateofAppointment') {
            x.value = this._datepipe.transform(x.value, 'yyyy-MM-dd');
          }
        }
      })
    }
    return json;
  }

  convertFormattingInDateString(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertFormattingInDateString(x)
        }
        else {
          if (x.field == 'dateofAppointment') {
            let formattedDate: any = this._datepipe.transform(x.value, 'MMMM d, y');
            x.value = new Date(formattedDate)
          }
        }
      })
    }
    return json;
  }

  setStyleOfPopup() {
    setTimeout(() => {
      const animationContainer = document.querySelector('.k-animation-container.k-animation-container-shown');
      if (animationContainer) {
        const popupElement = animationContainer as HTMLElement;
        if (popupElement) {
          if (animationContainer.childNodes[0] && (animationContainer.childNodes[0] as HTMLElement).className === 'k-popup') {
            popupElement.classList.add('columnMenuFilter_contact');
            animationContainer.querySelector('kendo-grid-columnlist')?.classList.add('d-block')
            animationContainer.querySelector('.k-column-chooser-title')?.classList.add('d-block')
          }
        }
      }

    }, 200);
  }

  // userInformation() {
  //   let userDetailsData = JSON.parse(localStorage.getItem('userDetails')!)
  //   if (userDetailsData) {
  //     userDetailsData?.user?.roles.forEach((x: any) => {
  //       if (x.roleName == 'Admin') {
  //         this.isValidUser = true;
  //       }
  //     })
  //   }
  // }

  resetAllfunctionality() {
    if (this.appointmentFilterJsonPayload == '' || this.appointmentFilterJsonPayload == undefined) this.appointmentFilterJsonPayload = sessionStorage.getItem('appointmentFilterStorage');
    let temppayload = JSON.parse(this.appointmentFilterJsonPayload ? this.appointmentFilterJsonPayload : '{}');
    let value = this.selectResetArr;
    if (value['sorting']) {
      this.sort = [
        {
          field: 'dateofAppointment',
          dir: 'asc',
        }
      ];

      this.skip = 0;
      this.pageNo = 1;
      temppayload.pageNumber = 1;
      temppayload.pageSize = 100;
      temppayload.skip = 0;
      this.sort = this.sort;
      temppayload.sortCol = "dateofAppointment"
      temppayload.sortDir = 'asc'
      // this.totalCount = this.finalWBAList.total;
    }
    // if (value["filter"]) {
    //   this.pageNo = 1;
    //   this.skip = 0;
    //   this.pageSize = 100;
    //   this.searchText = "";
    //   this.totalCount = 0;
    //   if (this.selectedListId == 0) {
    //     this.filterValue = { logic: 'and', filters: [] };
    //     temppayload.filterJson = ""
    //   }
    // }
    if (value["columnFilter"]) {
      this.pageNo = 1;
      this.skip = 0;
      this.pageSize = 100;
      this.searchText = "";
      this.totalCount = 0;
      temppayload.pageNumber = 1;
      temppayload.pageSize = 100;
      temppayload.skip = 0;
      this.gridFilters = { logic: 'and', filters: [], };
      temppayload.gridFilterJson = "";
      temppayload.searchText = "";
      this.gridFilterForArrayAppointmentStatus = [];
      this.gridFilterArrayForEventType = [];
      this.gridFilterForArrayAppointmentType = [];
    }


    if (value["columnSequence"]) {
      this.orderedCols = this.ColumnOrderMaster;
    }
    if (value["width"]) {
      this.columnsOfList.forEach(s => s.width = this.columnWidthMaster[s.columnName]);
    }

    if (value["column"]) {
      this.columnsOfList = this.columnsOfListMasterData;
    }
    this.selectResetArr = {};
    (document.getElementById('columnSequence') as HTMLInputElement).checked = false;
    (document.getElementById('columnFilter') as HTMLInputElement).checked = false;
    // (document.getElementById('filter') as HTMLInputElement).checked = false;
    (document.getElementById('sorting') as HTMLInputElement).checked = false;
    (document.getElementById('width') as HTMLInputElement).checked = false;
    (document.getElementById('column') as HTMLInputElement).checked = false;
    (document.getElementById('resetAll') as HTMLInputElement).checked = false;
    this.appointmentFilterJsonPayload = JSON.stringify(temppayload);
    if (this.appointmentFilterJsonPayload == '{}') this.appointmentFilterJsonPayload = "";
    (document.getElementById('dropdownMenuButton3') as HTMLButtonElement).click();
    if (this.reordered) this.reloadComponent(true);
    this.saveUserMasterGridColumn(true);
    // this.getAppointmentList(true);
  }

  reloadComponent(self: boolean, urlToNavigateTo?: string) {
    //skipLocationChange:true means dont update the url to / when navigating
    console.log("Current route I am on:", this.router.url);
    const url = self ? this.router.url : urlToNavigateTo;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/${url}`]).then(() => {
        console.log(`After navigation I am on:${this.router.url}`)
      })
    })
  }

  saveUserMasterGridColumn(call: boolean = false) {
    let columnList = { viewColumns: this.columnsOfList, columnOrder: this.orderedCols }
    let opts = {

      listId: this.selectedListId,
      module: "Appointment",
      columnList: columnList,
      filterJson: this.appointmentFilterJsonPayload,

    }
    this.userManagementService.saveGridFilterColumnList(opts).subscribe(s => {
      if (call) this.getDynamicColumnList()
    })
  }

  onChangeResetAll(event: any) {
    if (event.target.checked) {
      this.selectResetArr = {
        sorting: true,
        filter: true,
        columnFilter: true,
        columnSequence: true,
        width: true,
        column: true
      };
      // this.isFilter1 = false;
      (document.getElementById('columnSequence') as HTMLInputElement).checked = true;
      (document.getElementById('columnFilter') as HTMLInputElement).checked = true;
      // (document.getElementById('filter') as HTMLInputElement).checked = true;
      (document.getElementById('sorting') as HTMLInputElement).checked = true;
      (document.getElementById('width') as HTMLInputElement).checked = true;
      (document.getElementById('column') as HTMLInputElement).checked = true;
    } else {
      (document.getElementById('columnSequence') as HTMLInputElement).checked = false;
      (document.getElementById('columnFilter') as HTMLInputElement).checked = false;
      // (document.getElementById('filter') as HTMLInputElement).checked = false;
      (document.getElementById('sorting') as HTMLInputElement).checked = false;
      (document.getElementById('width') as HTMLInputElement).checked = false;
      (document.getElementById('column') as HTMLInputElement).checked = false;
      (document.getElementById('resetAll') as HTMLInputElement).checked = false;
      this.selectResetArr = {}
    }
  }

  arraysAreIdentical(arr1: any, arr2: any) {


    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  areWidthsEqual(columns1: any, columns2: any) {


    // Iterate over each column in the first array
    for (let i = 0; i < columns1.length; i++) {
      const columnName = columns1[i].columnName;

      // Check if the width of the column in the first array is defined
      if (columns1[i].width === undefined) {
        return false;
      }

      // Check if the width of the column in the first array is equal to the width in the second array
      if (columns1[i].width != 0 && columns1[i].width !== columns2[columnName]) {
        return false;
      }
    }
    return true;
  }

  compareVisibility(originalJson: any, updatedJson: any) {
    for (let i = 0; i < originalJson.length; i++) {
      const originalColumn = originalJson[i];
      const updatedColumn = updatedJson.find((column: any) => column.columnName === originalColumn.columnName);


      if (originalColumn.isVisible !== updatedColumn.isVisible) {
        return true;
      }
    }

    return false;
  }

  GridColumnWidth(colName: string): number {
    let width: number = 200;

    try {
      if (this.columnsOfList.length > 0) {
        let columnInfo = this.columnsOfList.find(x => x.columnName.toLowerCase() == colName.toLowerCase())
        if (columnInfo != undefined)
          width = columnInfo.width;
      }
    }
    catch (e) {
      width = 200
    }
    return width
  }
}
